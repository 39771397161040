// https://github.com/gatsbyjs/gatsby-starter-video 참조할 것

import React, { useEffect } from "react"
import { graphql } from "gatsby"
import $ from "jquery"

import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import Videos from "../components/js/page/videos"

import "../components/css/post/video-post.css"
import "../components/css/markdown.css"
import videosData from "./../data/videos.yaml"

import youtubeLinkLogo from "./../images/interface/youtube-link-logo.png"

// import banner_0 from "./../images/banners/banner_0.png"
// import banner_1 from "./../images/banners/banner_1.png"
// import banner_2 from "./../images/banners/banner_2.png"

import copy from 'copy-to-clipboard';
import toastr from 'toastr'

const VideoPostTemplate = ({ data, location }) => {

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  const videoData = videosData.filter((video) => {
    return video.idx === post.frontmatter.idx
  })[0]

  const tags = videoData.tags.map((tag) => { return tag.item })

  const replacement = (videoData) => {
    if ([1, 25, 26].includes(videoData.idx)) {
      return (
        <div id="replacement">
          <h1>
            <i>필독</i> 새 Git 강좌가 출시되었습니다!
          </h1>
          <b>최신 강좌</b>를 수강하시려면 아래의 링크를 클릭해주세요!
          <a target="_blank" href="https://youtu.be/1I3hMwQU6GU" rel="noreferrer">
            <span>NEW</span>
            제대로 파는 Git & GitHub 보러가기
          </a>

          <span class="material-icons">
            arrow_downward
          </span>
          &nbsp;
          아래의 컨텐츠는 Git을 간략히만 알려주는 <b>예전 강의</b>입니다.
        </div>
      )
    }
    return ('')
  }

  let disqusConfig = {
    url: `https://www.yalco.kr${location.pathname}`,
    identifier: String(videoData.idx),
    title: videoData.title,
  }

  // const bannderIdx = Math.floor(Math.random() * 1) // * 3이었음
  // const banner = [banner_0, banner_1, banner_2][bannderIdx]
  // const bannerLink = [
  //   'https://smartstore.naver.com/honeykers',
  //   // 'https://marpple.shop/kr/yalco',
  //   // 'https://marpple.shop/kr/yalco'
  // ][bannderIdx]

  useEffect(() => {
    $('deckgo-highlight-code').off('click').on('click', (e) => {
      const text = $(e.target).find('code').text()
      copy(text);
      toastr.success('코드가 복사되었습니다.')
    })
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={videoData.title}
        description={videoData.description}
      />
      {replacement(videoData)}
      <iframe 
        id="video-iframe" 
        title={videoData.title}
        src={`https://www.youtube.com/embed/${videoData.embed}`} 
        frameBorder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen>
      </iframe>
      <article className="post markdown-body">
        {/* <h1>
          {videoData.title}
        </h1> */}
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <a href={`https://youtu.be/${videoData.embed}`} target="_new">
          <div id="youtube-link">
            <img alt="youtube" src={youtubeLinkLogo}></img>
            유튜브에서 영상 보기
          </div>
        </a>
      </article>

      <br></br>
      <br></br>

      {/* <section className="centered">
        <a href={bannerLink} target="_new">
          <img className="banner" alt="banner" src={banner}></img>
        </a>
      </section> */}

      <br></br>
      <br></br>

      <section className="centered">
        <h2 id="tag-other-videos">관련 태그의 다른 영상들</h2>
      </section>

      <Videos 
        tagsOn={tags}
      ></Videos>

      <section className="centered disqus">
        <CommentCount config={disqusConfig} placeholder={'...'} />
        <Disqus config={disqusConfig} />
      </section>

      <footer>
      </footer>
    </Layout>
  )
}

export default VideoPostTemplate

export const pageQuery = graphql`
  query VideoPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        idx
      }
    }
  }
`